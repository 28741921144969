import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Badge, Button, Form, Image, Table } from 'react-bootstrap';
import './ShoppingList.css'
import { EyeFill, PencilFill, Trash, TrashFill } from 'react-bootstrap-icons';
import config from '../../config/config';
import Modal from 'react-bootstrap/Modal';

function ShoppingList({userCreds}) {
  const defaultItem = { "id": "", "itemName": "", "ischecked": "0" };

  const inputRef = useRef(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(defaultItem);
  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  const [modalItem, setModalItem] = useState('');

  const handleClose = () => setShow(false);

  const  headerZ = {
    Authorization: `Basic ${btoa(userCreds.username+':'+userCreds.password)}`,
  };
  useEffect(() => {
    fetchdata();
  },[]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  async function fetchdata() {
    try {
      const response = await axios.get(`${config.api.baseUrl}/items`,{
        headers: headerZ,
      });
      if (Array.isArray(response.data)) {
        setItems(response.data);
        setLoading(false);
      } else {
        console.error('Invalid response data:', response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function deleteItem(item) {
    setLoading(true);
    console.log(item);
    axios.delete(`${config.api.baseUrl}/items/${item}`, {
      headers: headerZ,
    })
      .then(function (response) {
        fetchdata();
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      }).finally(function (e) {
        setSelectedItem(defaultItem);
        setIsEditMode(false);
        setLoading(false);
      }
      );
  }



  const addItem = async () => {

    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('name', selectedItem.itemName);
    try {
      const response = await axios.post(`${config.api.baseUrl}/upload`, formData, {
        headers: {...headerZ,
          'Content-Type': 'multipart/form-data',
          
        },
      });
      fetchdata();
      setLoading(false);
      console.log(response.data);
      setSelectedItem(defaultItem);
    } catch (error) {
      console.error('Error uploading file and data:', error);
    }
   
  }

  const handleUserInputChange = (e) => {
    setSelectedItem({ ...selectedItem, itemName: e.target.value })
    //...
  }

  const handleEditItem = (item) => {
    console.log("handleEditItem", item);
    setIsEditMode(true);
    setSelectedItem(item);
    console.log("handleEditItem", selectedItem);
  }
  const updateItem = () => {
    editItem(selectedItem);
    setIsEditMode(false);
    setSelectedItem(defaultItem);
  }
  async function editItem(item) {
    setLoading(true);
    console.log("editItem", item);
    axios.put(`${config.api.baseUrl}/items/${item.id}`, item,
      {
        headers: headerZ,
      })
      .then(function (response) {
        fetchdata();
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      }).finally(function (e) {
        setSelectedItem(defaultItem);
        setIsEditMode(false);
        setLoading(false);
      }
      );
  }
  const toggleCheck = (item) => {
    console.log(item)
    const updatedStatus = item.checked === false ? true : false;
    const data = {
      itemName: item.itemName,
      id: item.id,
      checked: updatedStatus
    }
    editItem(data);
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      // Call your method or perform the desired action here
      addItem();
    }
  };
  const uncheckedItems = items.filter(i => i.checked === false);
  const checkedItems = items.filter(i => i.checked === true);

  const deleteChecked = () => {
    const itemsTodelete = [];
    checkedItems.forEach(function (item) {
      itemsTodelete.push(item.id);
    }, this)
    setLoading(true);
    console.log(itemsTodelete);
    axios.delete(`${config.api.baseUrl}/items`,{headers:headerZ,data: { ids: itemsTodelete }},)
      .then(function (response) {
        fetchdata();
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      }).finally(function (e) {
        setSelectedItem(defaultItem);
        setIsEditMode(false);
        setLoading(false);
      }
      );
  }

  const cancelEditOperation = ()=>
    {
      setSelectedItem(defaultItem);
      setIsEditMode(false);
      setLoading(false);
    }

    const openModal = (item) =>{
      setShow(true);
      setModalItem(item);

    }
    const removeAttachment = ()=>
    {

    }

    const handleImageError = () => {
      console.log("error image loading");
    };
  
  return (
    <div className="App">
      <h2>Shopping List</h2>
      {loading ? (
        // Display loader when loading is true
        <div id="loader"><p>Loading...</p></div>
      ) : <span></span>}
      <div> <Table bordered hover responsive>
        <thead>
          <tr>
            <th>X</th>
            <td>Name</td>
            <td>Actions</td>
            <td>Created</td>
          </tr>
        </thead>
        <tbody>
          {uncheckedItems.map((item) => (
            <tr key={item.id}>
              {/* <td><input type="checkbox" id="isChecked" name="isChecked" defaultChecked={item.ischecked=='0'? false:true} /></td> */}
              <td><Form.Check
                onChange={() => toggleCheck(item)}
                value={item.checked}
                type="checkbox"
                name="isChecked"
                checked={false}
              />{item.checked === "0" ? false : true}
              <span className="mycheckbox" onClick={() => toggleCheck(item)}></span>
              </td>
              <td>{item.itemName}</td>
              <td>
                <Button style={{marginRight:'5px'}} variant="danger" onClick={() => deleteItem(item.id)}><TrashFill size={15}/></Button>
                <Button style={{marginRight:'5px'}} variant="warning" data={item.hasAttachment} onClick={() => handleEditItem(item)}> <PencilFill size={15} /></Button>
                {item.hasAttachment ? (
                   <Button variant="info" onClick={()=>openModal(item)}><EyeFill size={15} /></Button>
              ) : null}
              </td>
              <td> {new Date(item.id).toLocaleDateString('en-UK')}</td>
            </tr>
          ))}
          {/* </tbody>
      </table> */}

          {/* <table id="checkedItems">
        <tbody> */}
          {checkedItems.map((item) => (
            <tr key={item.id} className='checkedItems '>
              {/* <td><input type="checkbox" id="isChecked" name="isChecked" defaultChecked={item.ischecked=='0'? false:true} onClick={()=>toggleCheck(item)} value={item.ischecked}/></td> */}
              <td><Form.Check
                onChange={() => toggleCheck(item)} value={item.checked}
                type="checkbox"
                name="isChecked"
                checked={true}
              
              />
              <span className="mycheckbox" onClick={() => toggleCheck(item)}></span>
              </td>
              <td><s>{item.itemName}</s></td>

              <td> <Button variant="danger" onClick={() => deleteItem(item.id)}><TrashFill size={15}/></Button></td>
              <td><s>{new Date(item.id).toLocaleDateString('en-UK')}</s></td>

            </tr>
          ))}

        </tbody>

      </Table>
        <Table responsive>
          <tbody>
            <tr>
              <td><Button disabled={checkedItems.length < 1} variant="danger" onClick={deleteChecked}><TrashFill size={15}/> All Checked <Badge pill  bg="dark">{checkedItems.length  }</Badge></Button></td>
            </tr>
          </tbody>
        </Table>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3">
            <Form.Control type="text" ref={inputRef} placeholder="Enter item here" name="name" onKeyDown={handleEnterPress} value={selectedItem.itemName} onChange={handleUserInputChange} />
          </Form.Group>
          {selectedItem.image &&
            <><Image width="100px" src={`${selectedItem.image}`} fluid />
            <Button variant="danger" onClick={()=>removeAttachment()}>X</Button>
            </>
          } 
          {!selectedItem.image &&
            <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={handleFileChange}/>
            </Form.Group>
          }
          <Form.Group className="mb-3">
            {!isEditMode && <Button id="saveButton" variant="primary" onClick={(e) => { e.preventDefault(); addItem() }}>
              Save
            </Button>}
            {isEditMode && <><Button id="updateButton" variant="primary" onClick={(e) => { e.preventDefault(); updateItem() }}>
              Update
            </Button>
              <Button  id="saveButton" variant="primary" onClick={(e) => { e.preventDefault(); cancelEditOperation() }}>
                Cancel
              </Button>
            </>
            }
          </Form.Group>

        </Form>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${modalItem.id}/${modalItem.itemName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={`${modalItem.image}`} alt="File is not image. Please click View/Download" onError={handleImageError} fluid />
          <p><a href={`${modalItem.image}`}>View/Download</a></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}



export default ShoppingList;