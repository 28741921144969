import { Nav, Navbar } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";

const Layout = ({ username, setIsLoggedIn }) => {

  const logOut = () =>
  {
    setIsLoggedIn(false);
    localStorage.removeItem('username');
    localStorage.removeItem('password');
  }
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
        <div className="container">
          <Navbar.Brand as={Link} to="/">
            Appz
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/blogs">
                Blogs
              </Nav.Link>
              <Nav.Link as={Link} to="/contact">
                Contacts
              </Nav.Link> */}
              <Nav.Link as={Link} to="/shoppinglist">
                Shoppinglist
              </Nav.Link>
              <Nav.Link as={Link} to="/expenso">
                Expenso
              </Nav.Link>
              <Nav.Link as={Link} to="/digimemo">
                DigiMemo
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                <b><i>Welcome, {username}</i></b>
              </Nav.Link>
              <Nav.Link onClick={() => logOut()}>
                Logout
              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className="container">
        <Outlet />
      </div>
    </>
  )
};

export default Layout;