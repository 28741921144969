import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import ShoppingList from './modules/shoppinglist/ShoppingList';
import 'bootstrap/dist/css/bootstrap.min.css';
import Expenso from './modules/expenso/Expenso';
import Login from './pages/Login'
import { useEffect, useState } from 'react';
import NotiToast from './modules/commons/NotiToast';
import DigiMemo from './modules/expenso/cashmemo/DigiMemo';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userCreds,setUserCreds] = useState({username:'',password:''})


  useEffect(() => {
    checkAuth();
  },[]);

  const checkAuth = () =>
  {
    const isUsernameSet = localStorage.getItem('username') !== null;
    if(localStorage.getItem('username') != null)
    {
      setUserCreds({
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password')
    });
      setIsLoggedIn(true);
    }
    else{
      setIsLoggedIn(false);
    }
    
  }
  return (
    <div className="App">
      {isLoggedIn && 
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout username={userCreds.username} setIsLoggedIn={setIsLoggedIn}/>}>
              <Route index element={<Home />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="contact" element={<Contact />} />
              <Route path="shoppinglist" element={<ShoppingList userCreds={userCreds}/>} />
              {/* <Route path="expenso" element={<Expenso />} /> */}
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      }
      {
        !isLoggedIn &&
        <Login setIsLoggedIn={setIsLoggedIn}  setUserCreds={setUserCreds}/>
      }
    {/* <NotiToast showToast={showToast} toastConfig={toastConfig}/> */}
    </div>
  );
}

export default App;
