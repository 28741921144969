
import { Accordion, Button, Modal, Toast, ToastContainer } from 'react-bootstrap';
import './Expenso.css';
import Category from './components/Category/Category';
import Transaction from './components/Transaction/Transaction';
import TransactionList from './components/TransactionList/TransactionList';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from './components/Loader';

function Expenso() {

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [transactions,setTransactions] = useState([]);
  const [toastConfig,setToastConfig] = useState({});
  const [categories,setCategories] = useState([]);

 const showToastNotification = (toastConfig)=>{
  setShowToast(true);
  setToastConfig(toastConfig);
 }

 const toggleShowToast = () => setShowToast(!showToast);

  async function fetchCategoriesData(){
    setIsLoading(true);
    await axios.get('http://exapi.vimalsurendran.com/categories',
    )
    .then(response => {
      setCategories(response.data.data)
    })
    .catch(error => {
      // Handle errors
    });
  }
  const auth = {
    headers: { 'Content-Type': 'application/json',
    'CustAuth': 'Basic YWRtaW46dmltYWxz',
} 
}
  async function fetchTransactionData(){
    
    
    await axios.get('http://exapi.vimalsurendran.com/transactions')
    .then(response => {
      setTransactions(response.data.data)
    })
    .catch(error => {
      // Handle errors
    }).finally(()=>{
      
    setIsLoading(false);
    });
  }
  useEffect(()=>{
    fetchCategoriesData();
    fetchTransactionData();
  },[])

  return (
    <div className="App">
      
     
      <h2>Xpenso</h2>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Transaction Management</Accordion.Header>
        <Accordion.Body>
          <TransactionList sectionTitle="Add Transaction" 
                  showToastNotification={showToastNotification} 
                  fetchTransactionData={fetchTransactionData} 
                  transactions={transactions} 
                  setIsLoading={setIsLoading} 
                  categories={categories}/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Category Management</Accordion.Header>
        <Accordion.Body>
          <Category sectionTitle="Category Management" 
          categories={categories}
          setIsLoading={setIsLoading} 
          showToastNotification={showToastNotification}
          fetchCategoriesData={fetchCategoriesData}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    <span id="addTransactionButton" className="bg-primary" onClick={handleShow}>
        +
      </span>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Transaction 
            showToastNotification={showToastNotification}
            fetchTransactionData={fetchTransactionData} 
            setIsLoading={setIsLoading}  
            categories={categories}/>
            </Modal.Body>
      </Modal>
      <ToastContainer
          className="p-3 position-fixed top-0 end-0"
          style={{ zIndex: 7 }}
        >
          <Toast bg={toastConfig.type} onClose={toggleShowToast} show={showToast} animation={true} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">{toastConfig.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body >{toastConfig.description}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Loader isLoading={isLoading}/>
    </div>
  );
}

export default Expenso;
