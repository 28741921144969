import axios from "axios";
import { useState } from "react";
import { Button, Col, Toast, ToastContainer } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import config from '../config/config';

function Login({ setIsLoggedIn,setUserCreds }) {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [showToast, setShowToast] = useState(false);
    const [toastConfig, setToastConfig] = useState({});
    const [loading, setLoading] = useState(false);

    const toggleShowToast = () => setShowToast(!showToast);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLoginSubmit(e);
        }
      };
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(`${config.api.authUrl}/public/login`);
        axios.post(`${config.api.authUrl}/public/login`,credentials,{
            auth: {
                  username: credentials.username,
                  password: credentials.password
            }
      })
        .then(function(response){
            
        setLoading(false);
            if(response.data.status==='success')
            {
                setIsLoggedIn(true);
                setUserCreds({
                    username: credentials.username,
                    password: credentials.password
              })
              localStorage.setItem('username', credentials.username);
              localStorage.setItem('password', credentials.password);
              const toastConfig = {
                title: 'Login Success', 
                description: 'Login Success',
                type: 'success'
            }
            setToastConfig(toastConfig);
            setShowToast(true);
            }
            else {
                const toastConfig = {
                    title: 'Login Failed',
                    description: 'User credentials not valid',
                    type: 'danger'
                }
                setToastConfig(toastConfig);
                setShowToast(true)
            }
        });
        
       

    }

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    return (
        <div className="container">
            {loading ? (
        // Display loader when loading is true
        <div id="loader"><p>Loading...</p></div>
      ) : <span></span>}
            <h2>Login</h2>
            <Form >
                <Form.Group className="mb-3" >
                    <Form.Label column sm="2">
                        Username
                    </Form.Label>
                    <Col sm="12">
                        <Form.Control type="text" name="username" onChange={handleChange} value={credentials.username} placeholder="Username" />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label column sm="2">
                        Password
                    </Form.Label>
                    <Col sm="12">
                        <Form.Control type="password" name="password" onChange={handleChange} onKeyPress={handleKeyPress} value={credentials.password} placeholder="Password" />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Button variant="primary" onClick={handleLoginSubmit}  >
                        Submit
                    </Button>
                </Form.Group>
            </Form>

            <ToastContainer
                className="p-3 position-fixed top-0 end-0"
                style={{ zIndex: 7 }}
            >
                <Toast bg={toastConfig.type} onClose={toggleShowToast} show={showToast} animation={true} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{toastConfig.title}</strong>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body >{toastConfig.description}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default Login;